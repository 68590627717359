<template>
  <div>
    <core-filter />

    <core-toolbar />

    <core-drawer />

    <core-view />
  </div>
</template>

<script>
import Filter from "../../components/core/Filter";
import Toolbar from "../../components/core/Toolbar";
import Drawer from "../../components/core/Drawer";
import View from "../../components/core/View";

export default {
    name: "App",

    components: {
        "core-filter": Filter,
        "core-toolbar": Toolbar,
        "core-drawer": Drawer,
        "core-view": View
    },

    data: () => ({
        //
    })
};
</script>

<style>

</style>