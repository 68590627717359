<template>
  <v-app-bar :color="isDark ? '#121212' : '#eff0f8'" app flat>
    <v-btn  icon @click="drawer">
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <v-toolbar-title
      :class="isDark ? 'blue-grey--text text--lighten-4' : 'blue-grey--text'"
    >
      {{ title }}
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-divider v-if="!isMobile" vertical></v-divider>

    <v-menu
      offset-x
      close-on-click
      right
      :max-width="250"
      :nudge-width="100"
      transition="slide-x-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-if="isMobile"
          icon
          :color="isDark ? 'blue-grey lighten-4' : 'blue-grey'"
          v-on="on"
        >
          <v-icon>mdi-account-circle-outline</v-icon>
        </v-btn>
        <v-btn
          v-else
          large
          tile
          text
          :color="isDark ? 'blue-grey lighten-4' : 'blue-grey'"
          v-on="on"
        >
          <span> profile </span>
          <v-icon right>mdi-account-circle-outline</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="mb-2 text-uppercase primary--text">
                profile name
              </v-list-item-title>
              <v-list-item-subtitle> rol </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider class="my-0 py-0"></v-divider>

        <v-list dense>
          <v-list-item>
            <v-list-item-icon class="mr-6">
              <v-icon>mdi-theme-light-dark</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Tema oscuro</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch
                small
                inset
                v-model="$vuetify.theme.dark"
                @change="saveTheme"
                color="success"
                class="ml-auto"
              ></v-switch>
            </v-list-item-action>
          </v-list-item>

          <v-list-item
            v-for="item in menuItems"
            :key="item.title"
            link
            :to="{ name: item.path }"
          >
            <v-list-item-icon class="mr-6">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="logout()">
            <v-list-item-icon class="mr-6">
              <v-icon>mdi-location-exit</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Salir</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
// import { mapMutations } from "vuex";
import moment from "moment";
export default {
  data: () => ({
    title: null,
    responsive: false,
    userData: {},
    menuItems: [
      { title: "Perfil", icon: "mdi-account-circle", path: "Perfil" },
    ],
  }),

  watch: {
    $route(val) {
      this.title = val.name;
    },

    size(value) {
      if (value == "xs" || value == "sm") {
        let value = this.$store.getters["GET_DRAWER"];
        value = !value;
        this.$store.commit("SETDRAWER", value);
      }
    },
  },

  created() {
    let theme = localStorage.getItem("theme");
    let isTrueSet = theme == "true";
    this.$vuetify.theme.light = isTrueSet;
  },

  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },

  computed: {
    size() {
      return this.$vuetify.breakpoint.name;
    },

    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    isDark() {
      return this.$vuetify.theme.dark;
    },
  },

  filters: {
    hour(value) {
      return moment(value).locale("es").format("LT");
    },
  },

  methods: {
    saveTheme() {
      localStorage.setItem("theme", this.$vuetify.theme.dark);
    },

    async GetProfileInfo() {
      try {
        await this.$store.dispatch("passport/ACTIVE_USER");
      } catch (error) {
        console.error("error al obtener perfil", error);
      }
    },

    userRol() {
      let rol;

      switch (this.userData.role_id) {
        case 1:
          rol = "Superusuario";
          break;

        case 2:
          rol = "Supervisor";
          break;

        case 3:
          rol = "Agente";
          break;

        default:
          break;
      }

      return rol;
    },

    drawer() {
      let value = this.$store.getters["GET_DRAWER"];
      value = !value;
      this.$store.commit("SETDRAWER", value);
    },

    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },

    async logout() {
      try {
        await this.$store.dispatch("auth/LOGOUT");
        this.$snotify.success("Nos vemos pronto.", "¡Hasta luego!");
        this.$router.push({name: "login"})
      } catch (e) {
        console.error(e);
        this.$snotify.error(e.response.data.error.message, "¡Error!");
      }
    },
  },
};
</script>

<style></style>
