<template>
    <v-main>
        <div class="px-1 px-md-10">
            <v-fade-transition mode="out-in" duration="200">
                <router-view />
            </v-fade-transition>
        </div>
    </v-main>
</template>

<script>
export default {};
</script>

<style></style>
