<template>
  <v-navigation-drawer
    v-model="Drawer"
    app
    mobile-breakpoint="991"
    width="250"
    class="bg-drawer"
  >
    <vuescroll
      :ops="{
        bar: { background: '#bacfcec0' }
      }"
    >
      <v-list-item>
        <v-list-item-title>
          <h3
            :class="
              $vuetify.theme.dark
                ? 'white--text text--darken-3'
                : 'grey--text text--darken-3'
            "
          >
            Grand Flex
          </h3>
        </v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav dense rounded>
        <div v-for="(item1, i) in routeListComputed" :key="i">
          <v-list-group v-if="item1.group" :prepend-icon="item1.icon" no-action>
            <template v-slot:prependIcon>
              <v-icon small right v-text="item1.icon"></v-icon>
            </template>

            <template v-slot:activator>
              <v-list-item-title
                class="ml-n3"
                style="max-width: 138px !important;display: flex;flex-wrap: wrap;white-space: normal;"
                v-text="item1.title"
              ></v-list-item-title>
            </template>

            <v-list-item
              v-for="(item2, j) in item1.routes"
              :key="j"
              :to="{ name: item2.path }"
              class="pl-10"
              active-class="secondary white--text active-shadow-item"
              style="text-decoration: none"
            >
              <v-list-item-title v-text="item2.title"></v-list-item-title>
            </v-list-item>
            <v-divider class="mb-2"></v-divider>
          </v-list-group>
          <v-list-item
            v-else
            no-action
            active-class="secondary white--text active-shadow-item"
            :to="{ name: item1.path }"
          >
            <v-list-item-icon class="mr-5">
              <v-icon small right v-text="item1.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item1.title"></v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
    </vuescroll>
  </v-navigation-drawer>
</template>

<script>
import vuescroll from "vuescroll";
import { routesList } from "./Utils/routeList";

export default {
  props: {
    // routesList: {
    //   type: Array,
    //   default: () => [],
    //   required: false,
    // },
  },

  components: {
    vuescroll
  },

  data() {
    return {
      // userPermission: [],
      userRouteList: []
    };
  },

  async created() {
    this.userRouteList = [...routesList];

    if (this.isMobile) {
      this.$store.commit("SETDRAWER", false);
    }
    await this.$store.dispatch("auth/GET_AUTH_USER");
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    Drawer: {
      get() {
        let drawer = this.$store.getters["GET_DRAWER"];
        return drawer === null ? true : drawer;
      },
      set(val) {
        this.$store.commit("SETDRAWER", val);
      }
    },

    userPermission() {
      if (this.$store.getters["auth/GET_USER"] != null) {
        const user = this.$store.getters["auth/GET_USER"].permissions.map(
          val => val.name
        );
        return user;
      } else {
        return null;
      }
    },

    routeListComputed() {
      let user;
      if (this.$store.getters["auth/GET_USER"] != null) {
        user = this.$store.getters["auth/GET_USER"].permissions.map(
          val => val.name
        );
        const list = this.userRouteList.filter(route => {
          if (!route.permission) {
            return true;
          }
          if (route.show === false) {
            return false;
          }
          if (route.dropdownItem) {
            return false;
          }
          if (user.includes(route.title)) {
            if (route.routes) {
              route["routes"] = route.routes.filter(
                subroute =>
                  user.includes(subroute.title) || !subroute.permission
              );

              console.log(route["routes"]);
            }
            return route;
          }
        });
        return list;
      } else {
        return [];
      }
    }
  },

  methods: {
    router(router) {
      this.$router.push({ name: router }).catch(err => {
        console.warn(err);
      });
    }
  }
};
</script>
<style>
.v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: 0 !important;
  min-width: 32px !important;
}
.v-list--dense .v-list-item .v-list-item__title {
  max-width: 185px !important;
  display: flex !important;
  flex-wrap: wrap !important;
  white-space: normal !important;
  padding: 12px 0 !important;
}
</style>
